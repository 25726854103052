import { Props as GalleryProps } from 'app/components/Gallery'
import { Props as HeroProps } from 'app/components/Hero'
import { Props as IntroRoomProps } from 'app/components/IntroRoom'
import { Props as OtherRoomsProps } from 'app/components/OtherRooms'
import { Props as SEOProps } from 'app/components/SEO'
import { Props as VisualBenefitsProps } from 'app/components/VisualBenefits'
import { CreatePagesQuery } from 'graphql-types'
import { compact } from 'lodash'

import { PageContext } from '.'

export interface Props {
  seoProps: SEOProps | undefined
  heroProps: HeroProps | undefined
  introRoomProps: IntroRoomProps | undefined
  galleryProps: GalleryProps | undefined
  visualBenefitsProps: VisualBenefitsProps | undefined
  otherRoomsProps: OtherRoomsProps | undefined
}

export const getRoomPageProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): Props => {
  return {
    seoProps: getSEOProps(query, pageContext),
    heroProps: getHeroProps(query, pageContext),
    introRoomProps: getIntroRoomProps(query, pageContext),
    galleryProps: getGalleryProps(query, pageContext),
    visualBenefitsProps: getVisualBenefitsProps(query, pageContext),
    otherRoomsProps: getOtherRoomsProps(query, pageContext),
  }
}

const getSEOProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): SEOProps | undefined => {
  const roomNodes = compact(query.cms.room).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const title = translation.metatag_title || undefined
  const description = translation.metatag_description || undefined
  const keywords = translation?.metatag_keywords || undefined
  const prefix = pageContext.languagePrefix
  const parentSlug = translation.parent_slug
  const slug = translation.slug

  return {
    title,
    description,
    keywords,
    languagePrefix: prefix || 'it',
    url: parentSlug
      ? `${prefix ? `/${prefix}` : ''}/${parentSlug}/${slug}`
      : `${prefix ? `/${prefix}` : ''}/${slug}`,
  }
}

const getHeroProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): HeroProps | undefined => {
  const roomNodes = compact(query.cms.room).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t: any) => t?.languages_code?.code === pageContext.languageCode,
  )

  const claim = translation?.claim || undefined
  const claimLabel = translation?.claim_label || undefined
  const heroImage = roomNodes.hero_image?.file?.childImageSharp
  const pax = translation?.pax || undefined
  const size = translation?.size || undefined
  const slide = heroImage
    ? [
        {
          alt: roomNodes.hero_image?.title || undefined,
          src: heroImage?.gatsbyImageData.images.fallback.src,
          srcSet: heroImage?.gatsbyImageData.images.fallback.srcSet,
          width: heroImage?.original?.width || undefined,
          height: heroImage?.original?.height || undefined,
        },
      ]
    : undefined

  return {
    claim,
    claimLabel,
    pax,
    size,
    slides: compact(slide),
  }
}

const getIntroRoomProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): IntroRoomProps | undefined => {
  const roomNodes = compact(query.cms.room).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const button = translation?.intro_button
  const cta = button?.label
    ? [
        {
          file: button?.file?.file?.publicURL || undefined,
          label: button?.label,
          target: button?.target || undefined,
          URL: button?.url || undefined,
        },
      ]
    : undefined
  const description = translation?.intro_description || undefined
  const plus = compact(translation.plus_list).map(({ icons_id }) => {
    const traslation = icons_id?.translations?.filter(
      (t) => t?.languages_code?.code === pageContext.languageCode,
    )[0]

    const icon = icons_id?.icon?.file?.publicURL || undefined
    const label = traslation?.label || undefined

    return {
      icon,
      label,
    }
  })
  const services = compact(translation.services_list).map(({ icons_id }) => {
    const traslation = icons_id?.translations?.filter(
      (t) => t?.languages_code?.code === pageContext.languageCode,
    )[0]

    const icon = icons_id?.icon?.file?.publicURL || undefined
    const label = traslation?.label || undefined

    return {
      icon,
      label,
    }
  })
  const title = translation?.intro_title || undefined

  return {
    cta,
    description,
    languageCode: pageContext.languageCode,
    plus,
    services,
    title,
  }
}

const getGalleryProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): GalleryProps | undefined => {
  const roomNodes = compact(query.cms.room).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const images = compact(roomNodes.gallery_images).map(
    ({ directus_files_id }) => {
      const image = directus_files_id?.file?.childImageSharp

      return {
        original: image?.gatsbyImageData.images.fallback.src,
        thumbnail:
          directus_files_id?.file?.thumbnail?.gatsbyImageData.images.fallback
            ?.src,
        title: directus_files_id?.title || undefined,
        width: image?.gatsbyImageData.width,
        height: image?.gatsbyImageData.height,
      }
    },
  )

  return {
    images,
    languageCode: pageContext.languageCode,
  }
}

const getVisualBenefitsProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): VisualBenefitsProps | undefined => {
  const collection = query.cms?.reservation_benefits

  if (!collection) {
    return undefined
  }

  const translation = collection.translations?.find(
    (t: any) => t?.languages_code?.code === pageContext.languageCode,
  )

  const benefits = compact(collection?.checklist).map(({ icons_id }) => {
    const traslation = icons_id?.translations?.filter(
      (t) => t?.languages_code?.code === pageContext.languageCode,
    )[0]

    const icon = icons_id?.icon?.file?.publicURL || undefined
    const label = traslation?.label || undefined

    return {
      icon,
      label,
    }
  })
  const description = translation?.description || undefined
  const IBEID = query.cms.company?.ibeid || undefined
  const image =
    collection.image?.file?.childImageSharp?.gatsbyImageData.images.fallback.src

  return {
    benefits,
    description,
    IBEID,
    image,
    languageCode: pageContext.languageCode,
    languagePrefix: pageContext.languagePrefix || 'it',
  }
}

const getOtherRoomsProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): OtherRoomsProps | undefined => {
  const rooms = compact(query.cms.room)
    .filter((t) => t.id !== pageContext.id)
    .sort((a: any, b: any) => a.weight - b.weight)
    .map(({ status, translations }) => {
      if (status !== 'published') {
        return null
      }

      const translation = translations?.find(
        (t: any) => t?.languages_code?.code === pageContext.languageCode,
      )

      const title = translation?.title
      const parentSlug = translation?.parent_slug
        ? `/${translation?.parent_slug}`
        : ''
      const slug = translation?.slug
      const URL = pageContext.languagePrefix
        ? `/${pageContext.languagePrefix}${parentSlug}/${slug}`
        : `${parentSlug}/${slug}`

      return title
        ? {
            title,
            URL,
          }
        : undefined
    })

  return {
    languageCode: pageContext.languageCode,
    rooms: compact(rooms),
  }
}
