import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  rooms: RoomProps[]
}

export const OtherRooms = memo(function OtherRooms({
  languageCode,
  rooms,
}: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      <Title>{useVocabularyData('other-rooms', languageCode)}</Title>
      <Rooms dial={2} row wrap>
        {rooms.map((item, index) => (
          <Room key={index} to={item.URL}>
            {item.title}
          </Room>
        ))}
      </Rooms>
    </Container>
  )
})

export interface RoomProps {
  title: string
  URL: string
}

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  padding: 6.5rem 1.5rem 7.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 3.75rem 1.5rem 3.75rem;
  }

  @media (max-width: 767px) {
    padding: 3rem 1.5rem 3rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
`

const Rooms = styled(FlexBox)``

const Room = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 28px;
  margin: 3.125rem 2.8125rem 0;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 767px) {
    margin: 2.25rem 1.5rem 0;
  }
`
