import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  languageCode: string
  plus?: ServiceProps[]
  services?: ServiceProps[]
  title?: string
}

export const IntroRoom = memo(function IntroRoom({
  cta,
  description,
  languageCode,
  plus,
  services,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper dial={4} row wrap>
        <LeftSide>
          {title ? (
            <Fade bottom distance="3.75rem">
              <Title>{title}</Title>
            </Fade>
          ) : null}
          {description ? (
            <Fade bottom distance="3.75rem">
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </Fade>
          ) : null}
          {cta
            ? cta.map((item, index) => (
                <Fade bottom distance="3.75rem" key={index}>
                  <Button {...item} arrow />
                </Fade>
              ))
            : null}
        </LeftSide>
        <RightSide>
          {services && services.length > 0 ? (
            <>
              <Label>{useVocabularyData('facilities', languageCode)}</Label>
              <Checklist row wrap>
                {services?.map((item, index) => (
                  <Service key={index} {...item} />
                ))}
              </Checklist>
            </>
          ) : null}
          {plus && plus?.length > 0 ? (
            <>
              <Label>{useVocabularyData('plus', languageCode)}</Label>
              <Checklist row wrap>
                {plus?.map((item, index) => (
                  <Service key={index} {...item} />
                ))}
              </Checklist>
            </>
          ) : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  &:before {
    content: '';
    width: 50%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 0;
  }

  @media (max-width: 767px) {
    &:before {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 70.25rem;
  margin: 0 auto 9.75rem;
  padding: 0 1.5rem;

  @media (max-width: 1023px) {
    margin-bottom: 7.5rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 3.75rem 9.306vw 3.75rem 0;

  @media (max-width: 1023px) {
    padding: 3rem 3.75rem 3rem 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 3rem 0 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.9375rem;
  margin-bottom: 1.125rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const RightSide = styled.div`
  width: 50%;
  padding: 0.375rem 0 5rem 6.125rem;

  @media (max-width: 1023px) {
    padding: 0.375rem 0 3rem 3.75rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  margin: 4rem 0 0.75rem 0;
  text-transform: uppercase;
`

const Checklist = styled(FlexBox)`
  @media (max-width: 767px) {
    margin-right: -1.5rem;
  }
`
