import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  return (
    <Container row>
      {icon ? <SVG src={icon} width="24" height="20" alt={label} /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: calc(50% - 2.25rem);
  margin: 1.5rem 2.25rem 0 0;

  @media (max-width: 767px) {
    width: calc(50% - 1.5rem);
    margin-right: 1.5rem;
  }
`

const SVG = styled.img`
  width: auto;
  height: 1.25rem;
  margin-right: 0.75rem;
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.25rem;
`
