import styled from '@emotion/styled'
import { memo } from 'react'

export const Separator = memo(function Separator() {
  return (
    <Container>
      <Icon />
    </Container>
  )
})

const Container = styled.div`
  width: 4px;
  margin: 0 auto 6rem;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 3rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    transform: translateX(2px);
  }
  &:before {
    margin-bottom: 0.75rem;
  }
  &:after {
    margin-top: 0.75rem;
  }

  @media (max-width: 1023px) {
    margin-bottom: 3.75rem;
    &:before,
    &:after {
      height: 2.25rem;
    }
  }
`

const Icon = styled.div`
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  transform: rotate(45deg);
`
